<template>
  <div>
    <navbar-top middle="logo" />

    <div class="fixed inset-0 pt-12 bg-blue-850">
      <div class="wrapper">
        <h1 class="text-7xl font-light">404</h1>
        <p class="mt-4 mb-8">Такой страницы не существует :(</p>
        <router-link :to="{ name: 'PageDashboard' }"
                     class="px-4 py-2 rounded bg-blue-600 font-bold text-white">
          В главное меню</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarTop from "@/components/NavbarTop";

export default {
  components: { NavbarTop },
}
</script>

<style scoped lang="scss">
.wrapper {
  @apply absolute flex flex-col items-center justify-center m-auto text-white;
  top: 50%; right: 50%;
  transform: translate(50%,-50%);
  overflow-y: auto;
  overflow-x: hidden;

@media (max-width: 639px) {
  width: 80%;
}

@media (min-width: 640px) {
  width: 40%;
}

@media (min-width: 1024px) {
  width: 25%;
}

@media (min-width: 1536px) {
  width: 18%;
}
}
</style>
